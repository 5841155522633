<template>
<v-card class="mx-auto" max-width="1200" style="margin-top: -64px;">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">Omdirigeringstjänst och verktyg</v-toolbar-title>

        <v-spacer></v-spacer>

      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text style="height: auto;">

<v-tabs :vertical="isBigScreen">
      <v-tab>
        <v-icon left>mdi-earth-plus</v-icon>
        Skapa omdirigering
      </v-tab>
      <v-tab>
        <v-icon left>mdi-earth-minus</v-icon>
        Ta bort omdirigering
      </v-tab>
       <v-tab>
        <v-icon left>mdi-refresh</v-icon>
        Se omdirigeringar
      </v-tab>

      <!-- Skapa omdirigering -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Använd följande tjänst för att lägga till en omdirigering eller uppdatera en befintlig omdirigering.
            </p>

            <v-form ref="form">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field label="Domän eller subdomän (från) tex example.com" cols="6" required v-model="fields.redirect.from"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="URL (till) tex https://example.com" cols="6" required v-model="fields.redirect.to"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select :items="fields.redirect.statusCodeOptions" v-model="fields.redirect.statusCode" label="Statuskod">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-switch v-model="fields.redirect.includeWww" label="Inkludera www?">
                        </v-switch>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-switch v-model="fields.redirect.keepQuery" label="Behåll query?">
                        </v-switch>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn class="mt-md-3" block :color="fields.redirect.color" @click="redirect" v-if="!fields.redirect.loading">Skapa</v-btn>
                        <v-btn class="mt-md-3" block :color="fields.redirect.color" v-else>
                             <v-progress-circular indeterminate color="amber" size="20" width="2"></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <pre v-if="!fields.redirect.loading && fields.redirect.response != null">
                {{ fields.redirect.response }}
            </pre>
        </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Ta bort omdirigering -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Använd följande tjänst för att ta bort en omdirigering.
            </p>

            <v-form ref="form">
                <v-row>
                  <v-col cols="8" v-if="fields.deleteRedirect.items != null">
                    <v-autocomplete v-model="fields.deleteRedirect.model"
                      :disabled="false"
                      :items="redirectItems"
                      color="white"
                      item-text="from"
                      item-value="from"
                      label="Välj omdirigerad domän"
                      hide-no-data
                      return-object
                  ></v-autocomplete>
                    <v-expand-transition>
                      <v-list v-if="fields.deleteRedirect.model" class="pre-bg">
                        <v-list-item v-for="(field, i) in deleteRedirectFields" :key="i">
                          <v-list-item-content>
                            <v-list-item-title v-text="field.value"></v-list-item-title>
                            <v-list-item-subtitle v-text="field.key"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                  </v-expand-transition>
                  </v-col>

                  <v-col cols="8" md="12" v-else>
                    <v-btn class="mt-md-3" block color="error" @click="getRedirects">Gick ej att hämta omdirigeringar. Tryck för att försöka igen</v-btn>
                  </v-col>

                    <v-col cols="12" md="4" v-if="fields.deleteRedirect.items != null">
                        <v-btn class="mt-md-3" block :color="fields.deleteRedirect.color" @click="delRedirect" v-if="!fields.deleteRedirect.loading">Ta bort</v-btn>
                        <v-btn class="mt-md-3" block :color="fields.deleteRedirect.color" v-else>
                             <v-progress-circular indeterminate color="amber" size="20" width="2"></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <pre v-if="!fields.deleteRedirect.loading && fields.deleteRedirect.response != null">
                {{ fields.deleteRedirect.response }}
            </pre>
        </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Se omdirigeringar -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Använd följande tjänst för att analysera omdirigeringar som sker på URL:en.
            </p>

            <v-form ref="form">
                <v-row>
                    <v-col cols="12" md="8">
                        <v-text-field label="URL" cols="6" required v-model="fields.redirects.url"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn class="mt-md-3" block :color="fields.redirects.color" @click="redirects" v-if="!fields.redirects.loading">Sök</v-btn>
                        <v-btn class="mt-md-3" block :color="fields.redirects.color" v-else>
                             <v-progress-circular indeterminate color="amber" size="20" width="2"></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <pre v-if="!fields.redirects.loading && fields.redirects.response != null">
                {{ fields.redirects.response }}
            </pre>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>
    </v-card-text>

    <v-dialog v-model="fields.redirect.existsAlready" max-width="390">
        <v-card>
        <v-card-title class="headline">Omdirigering existerar redan!</v-card-title>

            <v-card-text>
                En omdirigering för {{ fields.redirect.from }} finns redan. Vill du uppdatera den?
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn small color="success" @click="redirect('', true)">
            Ja
            </v-btn>

            <v-btn small color="error" @click="fields.redirect.existsAlready = false">
            Nej
            </v-btn>

        </v-card-actions>
        </v-card>
    </v-dialog>
    </v-card>
</template>

<script>

export default {
  name: 'hello',
  data() {
    return {
      fields: {
          redirect: {
              loading: false,
              from: null,
              to: null,
              protocol: 'both',
              keepQuery: false,
              statusCode: null,
              statusCodeOptions: ['301', '302'],
              includeWww: false,
              color: 'primary',
              response: null,
              existsAlready: false,
          },
          deleteRedirect: {
            loading: false,
            from: null,
            color: 'primary',
            model: null,
            items: null,
            response: null,
          },
          redirects: {
              loading: false,
              url: null,
              color: 'primary',
              response: null,
          },
      },
    }
  },

  created: function() {
    this.getRedirects()
  },

  computed: {
    isBigScreen: function() {
        if(screen.width < '768'){
            return false
        }

        return true
    },

    redirectItems: function() {
      return this.fields.deleteRedirect.items.map(entry => {
          return Object.assign({}, entry)
        })
    },

    deleteRedirectFields: function() {
      if (!this.fields.deleteRedirect.model) return []

        return Object.keys(this.fields.deleteRedirect.model).map(key => {
          return {
            key,
            value: this.fields.deleteRedirect.model[key] || 'n/a',
          }
        })
    }
  },


  methods: {
      redirect: function (event, update = false) {
        const { from, to, protocol, keepQuery, statusCode, includeWww } = this.fields.redirect
        this.fields.redirect.loading = true
        this.fields.redirect.response = null
        this.fields.redirect.existsAlready = false

        this.$http.post(`${this.$redirect.url}/api/redirects/add`, { 
            from: from,
            to: to,
            protocol: protocol,
            statusCode: statusCode,
            query: keepQuery,
            tenantId: '1',
            includeWww: includeWww,
            shallUpdate: update
         })
        .then(res => {
            this.fields.redirect.response = res.data
        }).catch(err => {
            if(err.response.data.message.includes('Omdirigering finns')) {
                this.fields.redirect.existsAlready = true
            } else {
               this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Felmeddelande',
                    text: err.response.data.message,
                    duration: 5000,
                }); 
            }

        }).finally(() => ( this.fields.redirect.loading = false ))
      },

      delRedirect: function() {
        const { from } = this.fields.deleteRedirect.model
        this.fields.deleteRedirect.loading = true
        this.fields.deleteRedirect.response = null

          this.$http.post(`${this.$redirect.url}/api/redirects/delete`, { from: from })
          .then(res => {
              this.fields.deleteRedirect.response = res.data
              this.getRedirects()
          }).catch(err => {
              this.$notify({
                group: 'foo',
                type: 'error',
                title: 'Felmeddelande',
                text: err.response.data.message,
                duration: 5000,
                });
          }).finally( () => ( this.fields.deleteRedirect.loading = false ))
      },

      getRedirects: function() {
          this.fields.deleteRedirect.select = { from: null, to: null, statusCode: null }

            this.$http.get(`${this.$redirect.url}/api/redirects/all`)
            .then(res => {
                this.fields.deleteRedirect.items = res.data
            }).catch(()=> {
                this.$notify({
                  group: 'foo',
                  type: 'error',
                  title: 'Felmeddelande',
                  text: 'Gick ej att hämta omdirigeringar från databasen!',
                  duration: 5000,
                  });
            })
        },

        redirects: function () {
          const { url } = this.fields.redirects
          this.fields.redirects.loading = true
          this.fields.redirects.response = null

            this.$http.post(`${this.$backend.url}/api/redirects`, { url: url })
            .then(res => {
                this.fields.redirects.response = res.data
            }).catch(err => {
                this.$notify({
                  group: 'foo',
                  type: 'error',
                  title: 'Felmeddelande',
                  text: err.response.data.message,
                  duration: 5000,
                  });
            }).finally(() => ( this.fields.redirects.loading = false))
        },
      }
}
</script>
