const backend = {
    url: process.env.VUE_APP_BACKEND,
}

const redirect = {
    url: process.env.VUE_APP_REDIRECTMANAGER
}

const app = {
    title: process.env.VUE_APP_TITLE,
    url: process.env.VUE_APP_FRONTEND
}

const azure = {
    tenant: {
        name: process.env.VUE_APP_AZURE_TENANT_NAME,
        id: process.env.VUE_APP_AZURE_CLIENT_ID,   
    }
}

module.exports = {
    backend,
    redirect,
    app,
    azure,
}
