<template>
<v-app>
<notifications group="foo" position="top center">
</notifications>
<!-- menu start -->
<v-sheet class="overflow-hidden" style="position: relative;" v-if="isAuthenticated">

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list-item>
        <v-list-item-avatar color="purple">
          <span class="white--text subtitle-1">{{ avatar }}</span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ name }}</v-list-item-title>
          <v-list-item-title class="caption" style="font-size:10px !important;">{{ email }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>

        <v-list-item v-for="item in items" :key="item.title" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <router-link :to="item.path"><v-list-item-title >{{ item.title }}</v-list-item-title></router-link>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="red" @click="logout()">Logga ut</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
</v-sheet>  
<!-- menu end -->
<!-- content start -->
 <v-card flat height="100%">
    <v-toolbar color="purple " dark extended flat>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="isAuthenticated">
        <v-icon>mdi-format-align-justify</v-icon>
        </v-app-bar-nav-icon>
    </v-toolbar>

    <router-view></router-view>

  </v-card>
<!-- content end -->

<!-- footer start -->
  <v-card height="400px">
      <v-footer :padless="true" :fixed="true">
        <v-card flat tile width="100%" class="lighten-1 text-center" style="border-top: 1px solid #252525;">
          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} — <strong>100 Procent Media AB</strong>
          </v-card-text>
        </v-card>
      </v-footer>

      <v-row
        align="center"
        justify="center"
        class="ma-12"
      >
        <v-col
          cols="12"
          md="8"
        >
        </v-col>
      </v-row>
  </v-card>
<!-- footer end -->
</v-app>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: true,
      isAuthenticated: this.$authentication.isAuthenticated(),
      isAdmin: this.$authentication.isAdmin(),
      drawer: null,
      items: [
          { title: 'Dashboard', icon: 'mdi-home', path: '/' },
          { title: 'Webbtjänster', icon: 'mdi-web', path: '/web' },
          { title: 'E-posttjänster', icon: 'mdi-email', path: '/email' },
          { title: 'Omdirigeringstjänst', icon: 'mdi-refresh', path: '/redirect' },
          { title: 'Tjänsters status', icon: 'mdi-power-plug', path: '/status' },
        ]
    }
  },

  computed: {
    width: function() {
      const { isCollapse} = this
      
      if(isCollapse) { return '120px'} else { return '300px'}
    },
    
    name: function() {
      if(!this.isAuthenticated)
        return null
      
      return this.$authentication.getUserName()
    },

    email: function() {
      if(!this.isAuthenticated)
        return null
      
      return this.$authentication.getUserEmail()
    },

    avatar: function() {
      const { name } =  this
      const regex = /^([A-Z])(?:\w+\s+)([A-Z])(?:\w+(?:\w+|å|ä|ö|Å|Ä|Ö)\w+)$/gm
      var firstNameAndLastName = regex.exec(name)
      return `${firstNameAndLastName[1]}${firstNameAndLastName[2]}`
    }
  },
  
  methods: {
    handleOpen() {
      this.isCollapse = false
    },

    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    requireAdministrator: function(item) {
      console.log(`(${item.title}) require admin: ${item.requiresAdministrator}, isAdmin: ${this.isAdmin}`)
      if(item.requiresAdministrator && this.isAdmin) {
        return true
      } else if(!item.requiresAdministrator) {
        return true
      } else {
        return false
      }

    },


    logout() {
      this.$authentication.signOut()
    }
  }

}
</script>

<style>

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.v-navigation-drawer a {
  color: #fff !important;
  text-decoration: none;
}

.pre-bg {
    border-radius: 0px !important;
    background: #202020 !important;
    border: 1px solid #404040 !important;
    color: #e7e7e7 !important; 
}

pre {
    background: #202020;
    border: 1px solid #404040;
    /* border-left: 3px solid green; */
    color: #e7e7e7;
    page-break-inside: avoid;
    font-family: monospace;
    /* font-size: 15px; */
    line-height: 1.6;
    /* margin-bottom: 1.6em; */
    max-width: 100%;
    overflow: auto;
    padding: 1em 1em;
    display: block;
    word-wrap: break-word;
    white-space: pre-line;
    max-width: 1000px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>