<template>
<v-card class="mx-auto" max-width="1200" style="margin-top: -64px;">
    <v-toolbar flat>
        <v-toolbar-title class="grey--text">E-post</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text style="height: auto;">
        <v-tabs :vertical="isBigScreen">
            <v-tab>
                <v-icon left>mdi-earth</v-icon>
                Sendgrid
            </v-tab>
            <v-tab>
                <v-icon left>mdi-collage</v-icon>
                SMTP
            </v-tab>
            <!-- Sendgrid -->
            <v-tab-item>
                <v-card flat>
                <v-card-text>
                    <p>
                    <strong class="red--text">OBS!</strong> Följande information är hämtas direkt från Sendgrid med datumintervallen som anges nedan.
                    </p>

                    <v-row v-if="!this.fields.sendgrid.loading && this.fields.sendgrid.response != null && this.fields.sendgrid.response != undefined && !this.fields.sendgrid.error">
                        <v-col cols="12" md="6" sm="12" xs="12">
                            <v-card elevation="2" color="grey darken-4" style="border:1px solid #303030 !important">
                            <v-card-title>Bounces</v-card-title>
                            <v-card-subtitle><strong :class="(this.fields.sendgrid.response.bounces.count > 1) ? 'amber--text' : 'green--text'">{{ this.fields.sendgrid.response.bounces.count }}</strong> e-postmeddelande har bouncat sedan  {{ getSendgridDate }}.</v-card-subtitle>
                            <v-card-actions>
                                <v-btn color="blue lighten-2" text @click="toggleBouncesDialog()">Se alla bounces</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" xs="12">
                            <v-card elevation="2" color="grey darken-4" style="border:1px solid #303030 !important">
                            <v-card-title>Blocks</v-card-title>
                            <v-card-subtitle><strong :class="(this.fields.sendgrid.response.blocks.count > 5) ? 'amber--text' : 'green--text'">{{ this.fields.sendgrid.response.blocks.count }}</strong> e-postmeddelanden har blockerats sedan  {{ getSendgridDate }}.</v-card-subtitle>
                            <v-card-actions>
                                <v-btn color="blue lighten-2" text @click="toggleBlocksDialog()">Se alla blocks</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" xs="12">
                            <v-card elevation="2" color="grey darken-4" style="border:1px solid #303030 !important">
                            <v-card-title>Autentiserade domäner</v-card-title>
                            <v-card-subtitle><strong :class="(this.fields.sendgrid.response.domains.count > 1) ? 'green--text' : 'red--text'">{{ this.fields.sendgrid.response.domains.count }}</strong> domäner har autentiserats.</v-card-subtitle>
                            <v-card-actions>
                                <v-btn color="blue lighten-2" text @click="toggleDomainsDialog()">Se alla domäner</v-btn>
                            </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-col cols="8" md="12" v-if="this.fields.sendgrid.loading && !this.fields.sendgrid.error">
                        <v-btn class="mt-md-3" block color="blue">Laddar data..</v-btn>
                    </v-col>

                    <v-col cols="8" md="12" v-if="!this.fields.sendgrid.loading && this.fields.sendgrid.error">
                        <v-btn class="mt-md-3" block color="error" @click="getSendgridStatistics">Gick ej att hämta Sendgrid statistik. Tryck för att försöka igen</v-btn>
                    </v-col>

                    
                </v-card-text>
                </v-card>
            </v-tab-item>

            <!-- SMTP -->
            <v-tab-item>
                <v-card flat>
                <v-card-text>
                    <p>Använd följande tjänst för att ansluta till en SMTP-server och skicka ett testmeddelande. Välj mellan autentisering, ssl, m.m.
                    </p>

                    <v-form ref="form">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field label="Server" cols="6" required v-model="fields.smtp.server" hint="Exempelvis mail-gw.100procent.com" autocomplete="off"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-text-field label="Port" cols="6" required v-model="fields.smtp.port" hint="Exempelvis 25, 2525, 587" autocomplete="off"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" v-if="fields.smtp.useAuth">
                                <v-text-field label="Användarnamn" cols="6" required v-model="fields.smtp.username" hint="Användarnamn att ansluta med" autocomplete="off"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" v-if="fields.smtp.useAuth">
                                <v-text-field label="Lösenord" cols="6" required v-model="fields.smtp.password" hint="Lösenord att ansluta med" :type="fields.smtp.showPassword ? 'text' : 'password'" :append-icon="fields.smtp.showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="fields.smtp.showPassword = !fields.smtp.showPassword" autocomplete="off"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field label="Från" cols="6" required v-model="fields.smtp.from" hint='Exempelvis "100 Procent" <hello@100procent.com>' autocomplete="off"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field label="Till" cols="6" required v-model="fields.smtp.to" hint="Exempelvis there@100procent.com" autocomplete="off"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-text-field label="Ämnesrad" cols="6" required v-model="fields.smtp.subject" hint="Hitta på något..." autocomplete="off"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-textarea outlined label="Body" required v-model="fields.smtp.body" hint="HTML funkar om du har bockat i Använd HTML" auto-grow clearable clear-icon="mdi-close-circle"></v-textarea>
                            </v-col>
                            
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-btn class="mt-md-3" block :color="fields.smtp.color" @click="smtp" v-if="!fields.smtp.loading">Anslut och skicka</v-btn>
                                    <v-btn class="mt-md-3" block :color="fields.smtp.color" v-else>
                                        <v-progress-circular indeterminate color="amber" size="20" width="2"></v-progress-circular>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-btn class="mt-md-3" block color="amber darken-2" @click="toggleSMTPDialog()">Övriga inställningar</v-btn>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-form>

                    <pre v-if="!fields.smtp.loading && fields.smtp.response != null">
                        {{ fields.smtp.response }}
                    </pre>
                </v-card-text>
                </v-card>
            </v-tab-item>

        </v-tabs>
    </v-card-text>

    <v-row justify="center">
    <v-dialog v-model="this.fields.smtp.advancedDialog" persistent max-width="990">
      <v-card>
        <v-card-title class="headline">
          Inställningar för SMTP
        </v-card-title>
        <v-card-text>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-switch v-model="fields.smtp.useAuth" label="Använd autentisering?"></v-switch>
                        <p>Bockar du i detta så kommer du bli ombedd att ange användarnamn och lösenord efter du stänger ned denna modal.</p>
                    </v-col>

                    <v-col cols="12" md="12">
                        <v-switch v-model="fields.smtp.useSSL" label="Använd SSL?"></v-switch>
                        <p>Bockar du i detta så kommer det försökas att ansluta via SSL.</p>
                    </v-col>

                    <v-col cols="12" md="12">
                        <v-switch v-model="fields.smtp.verifyCertificate" label="Verifiera server certifikat?"></v-switch>
                        <p>Bockar du i detta så kommer det verifieras om certifikatet är lokalt eller om det har gått ut.</p>
                    </v-col>

                    <v-col cols="12" md="12">
                        <v-switch v-model="fields.smtp.useHTML" label="Använd HTML?"></v-switch>
                        <p>Bockar du i detta så kommer e-postmeddelandet att skickas med HTML-format.</p>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="toggleSMTPDialog()">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="this.fields.sendgrid.bouncesDialog" persistent max-width="990" v-if="!this.fields.sendgrid.loading && this.fields.sendgrid.response != null && this.fields.sendgrid.response != undefined">
      <v-card>
        <v-card-title class="headline">
          Översikt av bounces
        </v-card-title>
        <v-card-text>
        <v-data-table :headers="this.fields.sendgrid.datatable.headers" :items="this.fields.sendgrid.response.bounces.items" :items-per-page="10" class="elevation-1">
        <template v-slot:item.created="{ item }">
            <v-chip>
                {{ convertUnixToDate(item.created) }}
            </v-chip>
        </template>
        </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="toggleBouncesDialog()">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="this.fields.sendgrid.blocksDialog" persistent max-width="990" v-if="!this.fields.sendgrid.loading && this.fields.sendgrid.response != null && this.fields.sendgrid.response != undefined">
      <v-card>
        <v-card-title class="headline">
          Översikt av blocks
        </v-card-title>
        <v-card-text>
        <v-data-table :headers="this.fields.sendgrid.datatable.headers" :items="this.fields.sendgrid.response.blocks.items" :items-per-page="10" class="elevation-1">
        <template v-slot:item.created="{ item }">
            <v-chip>
                {{ convertUnixToDate(item.created) }}
            </v-chip>
        </template>
        </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="toggleBlocksDialog()">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="this.fields.sendgrid.domainsDialog" persistent max-width="990" v-if="!this.fields.sendgrid.loading && this.fields.sendgrid.response != null && this.fields.sendgrid.response != undefined">
      <v-card>
        <v-card-title class="headline">
          Översikt av domäner
        </v-card-title>
        <v-card-text>
        <p>
            <strong class="red--text">OBS!</strong> Om det står <strong>100procent</strong> som användare betyder det att de ligger under vår delade miljö i Sendgrid. Har de sitt
            egna användarnamn så betyder de att de har en eller flera egna dedikerade IP-adresser.
        </p>
        <v-data-table :headers="this.fields.sendgrid.datatable.headers_domains" :items="this.fields.sendgrid.response.domains.items" :items-per-page="10" class="elevation-1">
        <template v-slot:item.last_validation_attempt_at="{ item }">
            <v-chip>
                {{ convertUnixToDate(item.last_validation_attempt_at) }}
            </v-chip>
        </template>
        </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="toggleDomainsDialog()">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</v-card>

</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      fields: {
          smtp: {
              response: null,
              loading: false,
              color: 'primary',

              server: null,
              port: null,

              username: null,
              password: null,
              showPassword: false,

              from: null,
              to: null,
              subject: null,
              body: null,

              advancedDialog: false,
              verifyCertificate: true,
              useAuth: false,
              useSSL: false,
              useHTML: true,

          },

          sendgrid: {
              response: null,
              loading: false,
              color: 'primary',
              error: false,
              
              blocksDialog: false,
              bouncesDialog: false,
              domainsDialog: false, 
              datatable: {
                    headers: [
                      { text: 'Från', align: 'start', value: 'email' },
                      { text: 'Skickad', sortable: true, value: 'created' },
                      { text: 'Status', sortable: false, value: 'status' },
                      { text: 'Fel', sortable: false, value: 'reason' }
                    ],

                    headers_domains: [
                      { text: 'Domän', align: 'start', value: 'domain' },
                      { text: 'Användare', sortable: false, value: 'username' },
                      { text: 'Validerad senast', sortable: true, value: 'last_validation_attempt_at' }
                    ]
                 
              }

          },
      },
    }
  },

  created: function() {
      this.getSendgridStatistics()
  },

  computed: {
    isBigScreen: function() {
        if(screen.width < '768'){
            return false
        }

        return true
    },

    getSendgridDate: function() {
        return (this.fields.sendgrid.response.domains.since.parsed != undefined) ? this.convertDateToReadable(this.fields.sendgrid.response.domains.since.parsed) : 'väntar..'
    }
  },


  methods: {

      smtp: function () {
        const smtp = this.fields.smtp
        const _this = this

        smtp.loading = true
        smtp.response = null

        this.$http.post(`${this.$backend.url}/api/email/smtp`, { 
            server: smtp.server, 
            port: smtp.port,
            verifyCertificate: smtp.verifyCertificate, 
            useAuth: smtp.useAuth, 
            useSSL: smtp.useSSL, 
            useHTML: smtp.useHTML,
            from: smtp.from,
            to: smtp.to,
            subject: smtp.subject,
            body: smtp.body,
            username: smtp.username,
            password: smtp.password,
        })
        .then(res => {
            smtp.response = res.data
        }).catch(err => {
            _this.$notify({
                group: 'foo',
                type: 'error',
                title: 'Felmeddelande',
                text: err.response.data.message,
                duration: 5000,
            })
        }).finally(() => ( smtp.loading = false ))
      },

      getSendgridStatistics: function() {
        const sendgrid = this.fields.sendgrid
        const _this = this

        sendgrid.loading = true
        sendgrid.response = null

        this.$http.get(`${this.$backend.url}/api/email/sendgrid`)
        .then(res => {
            sendgrid.response = res.data
            sendgrid.error = false
        }).catch(err => {
            sendgrid.error = true
            _this.$notify({
                group: 'foo',
                type: 'error',
                title: 'Felmeddelande',
                text: err.response.data.message,
                duration: 5000,
            })
        }).finally(() => ( sendgrid.loading = false ))
      },

      convertUnixToDate: function(unixTime) {
          return moment(unixTime * 1000).format('YYYY-MM-DD HH:mm:ss')

      },

      convertDateToReadable: function(date)
      {
          return moment(date).format('YYYY-MM-DD HH:mm:ss')
      },

      toggleSMTPDialog: function() {
          console.log('clicked')
          this.fields.smtp.advancedDialog = !this.fields.smtp.advancedDialog
      },

      toggleBlocksDialog: function() {
          this.fields.sendgrid.blocksDialog = !this.fields.sendgrid.blocksDialog
      },

      toggleBouncesDialog: function() {
          this.fields.sendgrid.bouncesDialog = !this.fields.sendgrid.bouncesDialog
      },

      toggleDomainsDialog: function() {
          this.fields.sendgrid.domainsDialog = !this.fields.sendgrid.domainsDialog
      }
  }
}
</script>
