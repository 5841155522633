<template>
<v-card class="mx-auto" max-width="700" min-height="400" style="margin-top: -64px;">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">{{ message }}</v-toolbar-title>

        <v-spacer></v-spacer>

      <v-tooltip bottom v-if="isAuthenticated">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="$router.push('/')">
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </template>
        <span>Gå till dashboard</span>
      </v-tooltip>

      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="login()">
            <v-icon>mdi-microsoft-azure</v-icon>
          </v-btn>
        </template>
        <span>Logga in med Azure</span>
      </v-tooltip>

      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text style="height: auto;">
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <img src="https://www.reactiongifs.com/r/2013/12/shall-not-pass.gif">
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>
<script>
export default {
  data() {
    return {
      isAuthenticated: this.$authentication.isAuthenticated(),
      isAdmin: this.$authentication.isAdmin(),
    }
  },

  created: function() {
    if(this.isAuthenticated && this.isAdmin) {
      this.$router.push('/')
    }
  },

  computed: {
    message: function() {
      if(!this.isAuthenticated)
      {
        return 'DU ÄR EJ INLOGGAD'
      }

      if(this.isAuthenticated && ! this.isAdmin)
      {
        return 'EJ TILLRÄCKLIGT MED BEHÖRIGHETER!!!11'
      }

      return '????'

    }
  },

  methods: {
    login: function() {
      this.$authentication.signIn()
    }
  }
}
</script>