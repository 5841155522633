import Vue from 'vue'
import qs from 'qs'
import App from './App.vue'
import axios from 'axios';
import router from './router'
import Notifications from 'vue-notification'
import vuetify from '@/plugins/vuetify'
import { backend, redirect, app,} from './helpers/config'
import authentication from './helpers/authentication'

Vue.config.productionTip = false

Vue.prototype.$authentication = authentication
Vue.prototype.$backend = backend
Vue.prototype.$redirect = redirect
// axios
// use it as this.$http
//
Vue.prototype.$http = axios
Vue.use(Notifications)
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.interceptors.request.use(config => {
  authentication.acquireToken().then(token => {
    config.headers.Authorization = `Bearer ${token}`
    config.data = qs.stringify(config.data)
  })
  return config
})
Vue.prototype.$app = app


authentication.initialize().then( () => {
  new Vue({
    render: h => h(App),
    router,
    vuetify
  }).$mount('#app')
})
