<template>
<v-card class="mx-auto" max-width="700" min-height="300" style="margin-top: -64px;">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">SIDAN HITTADES FAN INTE</v-toolbar-title>

        <v-spacer></v-spacer>

  <v-tooltip bottom v-if="isAuthenticated">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click="$router.push('/')">
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </template>
    <span>Gå till dashboard</span>
  </v-tooltip>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text style="height: 200px;" class="text-center">
        <v-row>
          <v-col cols="12" md="12">
            <img src="https://media2.giphy.com/media/SggILpMXO7Xt6/200.gif">
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>
<script>
export default {
  data() {
    return {
      isAuthenticated: this.$authentication.isAuthenticated(),
    }
  },

  created: function() {
    if(!this.isAuthenticated) {
      return this.$router.push('/unauthorized')
    }
  }
}
</script>