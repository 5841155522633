<template>
<v-card class="mx-auto" max-width="1200" style="margin-top: -64px;">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">Tjänsters status</v-toolbar-title>

        <v-spacer></v-spacer>

      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text style="height: auto;">

<v-tabs :vertical="false">
      <v-tab>
        <v-icon left>mdi-microsoft-azure</v-icon>
        Azure
      </v-tab>
      <v-tab>
        <v-icon left>mdi-cloud</v-icon>
        Cloudflare
      </v-tab>

      <!-- Azure -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Se status för Microsoft Azure nedan (iframe:ad för <a href="https://status.azure.com/sv-se/status" target="_blank">https://status.azure.com/sv-se/status</a>)
            </p>
            <iframe src="https://status.azure.com/sv-se/status" width="100%" height="450px"></iframe>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Cloudflare -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Se status för Cloudflare nedan (iframe:ad för <a href="https://www.cloudflarestatus.com" target="_blank">https://www.cloudflarestatus.com</a>)
            </p>
            <iframe src="https://www.cloudflarestatus.com" width="100%" height="450px"></iframe>
          </v-card-text>
        </v-card>
      </v-tab-item>
      
    </v-tabs>
      </v-card-text>
    </v-card>
</template>

<script>

export default {
  name: 'hello',
  data() {
    return {
    }
  },

  computed: {
    isBigScreen: function() {
        if(screen.width < '768'){
            return false
        }

        return true
    }
  },
}
</script>