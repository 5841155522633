import Vue from 'vue'
import Router from 'vue-router'

import Email from '@/components/Email'
import Home from '@/components/Hello'
import Status from '@/components/Status'
import Web from '@/components/Web'
import Redirect from '@/components/Redirect'
import NotFound from '@/components/placeholder/NotFound'
import Unauthorized from '@/components/placeholder/Unauthorized'
import authentication from './helpers/authentication'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      meta: { 
        requiresAuthentication: true
      }
    },

    {
      path: '/web',
      component: Web,
      meta: { 
        requiresAuthentication: true
      }
    },

    {
      path: '/redirect',
      component: Redirect,
      meta: { 
        requiresAuthentication: true
      }
    },

    {
      path: '/email',
      component: Email,
      meta: { 
        requiresAuthentication: true
      }
    },

    {
      path: '/status',
      component: Status,
      meta: { 
        requiresAuthentication: true,
        requiresAdministrator: true,
      }
    },

    {
      path: '/unauthorized',
      component: Unauthorized,
      meta: { 
        requiresAuthentication: false,
        requiresAdministrator: false,
      }
    },

    {
      path: '*',
      component: NotFound
    }
  ]
})

// Global route guard
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    if (authentication.isAuthenticated()) {
      next()
    } else {
      router.push('/unauthorized')
    }
  } else {
    next()
  }

  if (to.matched.some(record => record.meta.requiresAdministrator)) {
    if (authentication.isAdmin()) {
      next()
    } else {
      router.push('/unauthorized')
    }
  } else {
    next()
  }
});

export default router;