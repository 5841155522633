<template>
<v-card class="mx-auto" max-width="1200" style="margin-top: -64px;">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">Dashboard</v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text style="min-height: 300px;">
        <v-row v-if="isAdmin">

          <v-col cols="12" md="4">
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 primary--text">WEBB</div>
                  <v-list-item-title class="headline mb-1">Webbtjänster</v-list-item-title>
                  <v-list-item-subtitle>Whois (domain/ip), dns lookup samt certifikatanalys.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn block text @click="push('/web')">Gå dit</v-btn>
              </v-card-actions>
            </v-card>

          </v-col>

          <v-col cols="12" md="4">
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 blue--text">WEBB</div>
                  <v-list-item-title class="headline mb-1">Omdirigeringstjänst</v-list-item-title>
                  <v-list-item-subtitle>Visa, ändra, lägg till eller ta bort omdirigeringar</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn block text @click="push('/redirect')">Gå dit</v-btn>
              </v-card-actions>
            </v-card>

          </v-col>

          <v-col cols="12" md="4">
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 green--text">E-POST</div>
                  <v-list-item-title class="headline mb-1">E-posttjänster</v-list-item-title>
                  <v-list-item-subtitle>Diverse verktyg för e-post</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn block text @click="push('/email')">Gå dit</v-btn>
              </v-card-actions>
            </v-card>

          </v-col>

          <v-col cols="12" md="4">
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 orange--text">Diverse</div>
                  <v-list-item-title class="headline mb-1">Tjänststatus</v-list-item-title>
                  <v-list-item-subtitle>Se status hos Azure/Cloudflare</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn block text @click="push('/status')">Gå dit</v-btn>
              </v-card-actions>
            </v-card>

          </v-col>

          <v-col cols="12" md="4">
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 orange--text">Övervakning</div>
                  <v-list-item-title class="headline mb-1">Zabbix</v-list-item-title>
                  <v-list-item-subtitle>Snabblänk till Zabbix övervakningssystemet.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn block text @click="open('https://zabbix.100procent.com')">Gå dit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 orange--text">Övervakning</div>
                  <v-list-item-title class="headline mb-1">Nagios</v-list-item-title>
                  <v-list-item-subtitle>Snabblänk till Nagios övervakningssystemet.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn block text @click="open('https://nagios.intranet.100procent.com')">Gå dit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 orange--text">Övervakning</div>
                  <v-list-item-title class="headline mb-1">Grafana</v-list-item-title>
                  <v-list-item-subtitle>Snabblänk till interna Grafana</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn block text @click="open('https://overvakning.100procent.com:3000')">Gå dit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: 'hello',
  data() {
    return {
      isAuthenticated: this.$authentication.isAuthenticated(),
      isAdmin: this.$authentication.isAdmin(),
    }
  },

  methods: {
    push: function(path) {
      this.$router.push(path)
    },

    open: function(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
