<template>
<v-card class="mx-auto" max-width="1200" style="margin-top: -64px;">
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">Webb</v-toolbar-title>

        <v-spacer></v-spacer>

      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text style="height: auto;">

<v-tabs :vertical="isBigScreen">
      <v-tab>
        <v-icon left>mdi-account-search</v-icon>
        Whois
      </v-tab>
      <v-tab>
        <v-icon left>mdi-earth</v-icon>
        Sök DNS
      </v-tab>
      <v-tab>
        <v-icon left>mdi-shield-lock</v-icon>
        Certifikat
      </v-tab>
      
      <!-- whois -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Använd följande tjänst för att söka efter ägare på domänen, vart den är registrerad, när den skapades
                och förfaller. Även lite annan nyttig information.
            </p>

            <v-form ref="form">
                <v-row>
                    <v-col cols="12" md="8">
                        <v-text-field label="Domän eller IP" cols="6" required v-model="fields.whois.domain"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn class="mt-md-3" block :color="fields.whois.color" @click="whois" v-if="!fields.whois.loading">Sök</v-btn>
                        <v-btn class="mt-md-3" block :color="fields.whois.color" v-else>
                             <v-progress-circular indeterminate color="amber" size="20" width="2"></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <pre v-if="!fields.whois.loading && fields.whois.response != null">
                {{ fields.whois.response }}
            </pre>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- DNS -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Använd följande tjänst för att söka efter DNS på en domän. Exempelvis A, CNAME, MX, TXT, osv.
            </p>

            <v-form ref="form">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-btn class="mt-md-3" block :color="fields.dns.color" @click="dns">Ta mig till Google Dig</v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <pre v-if="!fields.dns.loading && fields.dns.response != null">
                {{ fields.dns.response }}
            </pre>
        </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Certifikat -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>Använd följande tjänst för att analysera säkerhetscertifikatet (<strong>SSL</strong>) för en domän.
            </p>

            <v-form ref="form">
                <v-row>
                    <v-col cols="12" md="8">
                        <v-text-field label="Domän eller subdomän" cols="6" required v-model="fields.certificate.domain"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn class="mt-md-3" block :color="fields.certificate.color" @click="certificate" v-if="!fields.certificate.loading">Sök</v-btn>
                        <v-btn class="mt-md-3" block :color="fields.certificate.color" v-else>
                             <v-progress-circular indeterminate color="amber" size="20" width="2"></v-progress-circular>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-switch v-model="fields.certificate.extended" label="Utökad info"></v-switch>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-switch v-model="fields.certificate.problems" label="Visa problem"></v-switch>
                    </v-col>
                </v-row>
            </v-form>

            <v-row v-if="fields.certificate.response != null">
                <v-col cols="12" md="12" v-if="fields.certificate.errors.length > 0 && fields.certificate.problems">
                    <v-card outlined>
                        <div v-for="(error, index) in fields.certificate.errors" :key="index">
                        <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">PROBLEM #{{ index + 1 }}</div>
                            <v-list-item-title class="headline mb-1">{{ error.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ error.description }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon :color="error.color">{{ error.icon }}</v-icon>
                        </v-list-item-avatar>
                        </v-list-item>
                        </div>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card outlined>
                        <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">UTFÄRDARE</div>
                            <v-list-item-title class="headline mb-1">{{ fields.certificate.response.issuer['O'] }}</v-list-item-title>
                            <v-list-item-subtitle v-if="fields.certificate.response.issuer['CN'] != undefined">{{ fields.certificate.response.issuer['CN'] }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon color="success">mdi-shield-lock-outline</v-icon>
                        </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card outlined>
                        <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">UTFÄRDAT</div>
                            <v-list-item-title class="headline mb-1">{{ fields.certificate.response.valid_from }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon color="grey">mdi-calendar-clock</v-icon>
                        </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card outlined>
                        <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">FÖRFALLER</div>
                            <v-list-item-title class="headline mb-1">{{ fields.certificate.response.valid_to }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon color="grey">mdi-calendar-clock</v-icon>
                        </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card outlined>
                        <div v-for="(domain, index) in getDomains" :key="index">
                            <v-list-item three-line>
                            <v-list-item-content>
                                <div class="overline mb-4">Domän #{{ index + 1 }}</div>
                                <v-list-item-title class="headline mb-1">{{ domain.domain }}</v-list-item-title>
                                <v-list-item-subtitle>{{ domain.domain }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar tile size="80">
                                <v-icon color="success">mdi-check-circle-outline</v-icon>
                            </v-list-item-avatar>
                            </v-list-item>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="12" v-if="!fields.certificate.loading && fields.certificate.response != null && fields.certificate.extended">
                <pre>
                    {{ fields.certificate.response }}
                </pre>
                </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>
    </v-card-text>
    </v-card>
</template>

<script>

export default {
  name: 'hello',
  data() {
    return {
        issuers: [
            'Let\'s Encrypt Authority X3', 
            'Go Daddy Secure Certificate Authority - G2',
            'DigiCert SHA2 Secure Server CA',
            'SwissSign Server Gold CA 2014 - G22',
            'GeoTrust TLS RSA CA G1',
            'GeoTrust TLS RSA CA G1',
            'COMODO RSA Extended Validation Secure Server CA',
            'Sectigo RSA Domain Validation Secure Server CA',
            'Amazon',
            'Microsoft IT TLS CA 1',
            'Microsoft IT TLS CA 5',
            'CloudFlare Inc ECC CA-2',
            'RapidSSL RSA CA 2018'
            ],
      fields: {
          whois: {
              loading: false,
              domain: null,
              color: 'primary',
              response: null,
          },
          dns: {
              loading: false,
              domain: null,
              color: 'primary',
              response: null,
              query: null,
              queries: ['Auto', 'A', 'CNAME', 'MX', 'TXT']
          },
          certificate: {
              loading: false,
              domain: null,
              color: 'primary',
              response: null,
              extended: false,
              problems: true,
              errors: []
          }
      },
    }
  },

  computed: {
    isBigScreen: function() {
        if(screen.width < '768'){
            return false
        }

        return true
    },

    getDomains: function() {
        const { subjectaltname } = this.fields.certificate.response
        const domains = []
        if(subjectaltname != null)
        {
            const pattern = /(?:DNS:)(\S+(?:,|$)){1}/g
            const hostnames = subjectaltname.split(',')
            for (const hostname of hostnames) {
                const x = hostname.replace(pattern, '$1').replace(' ', '')
                domains.push({
                domain: x
                })
            }    
        }
        return domains
    },

    checkIfDomainIsCertified() {
      const { domain } = this.fields.certificate
      const domains = this.getDomains
      var {
        isValid
      } = this

      for (const item of domains) {
        if (item.domain.toLowerCase().indexOf(domain) >= 0 || item.domain.toLowerCase().indexOf('*.' + domain) >= 0) {
          isValid = true
        }
      }

      return isValid
    },

    checkIfVerifiedIssuer() {
        const { issuers } = this
        const issuer  = this.fields.certificate.response.issuer['CN']
        if(issuer != undefined) {
            return issuers.includes(issuer)
        } else {
            return false
        }
    }
  },


  methods: {
      whois: function () {
        const domain = this.fields.whois.domain
        const _this = this

        _this.fields.whois.loading = true
        _this.fields.whois.response = null

          this.$http.post(`${this.$backend.url}/api/whois`, { domain: domain })
          .then(res => {
            _this.fields.whois.response = res.data
          }).catch(err => {
              _this.$notify({
                group: 'foo',
                type: 'error',
                title: 'Felmeddelande',
                text: err.response.data.message,
                duration: 5000,
                });
          }).finally(() => ( this.fields.whois.loading = false))
      },

      dns: function () {
        window.open('https://toolbox.googleapps.com/apps/dig/', '_blank')
      },

      certificate: function () {
        const { domain } = this.fields.certificate
        const _this = this
        this.fields.certificate.loading = true
        this.fields.certificate.response = null
        this.fields.certificate.errors = []

          this.$http.post(`${this.$backend.url}/api/certificate`, { domain: domain })
          .then(res => {
            _this.errors()
            _this.fields.certificate.response = res.data
            _this.errors(res.data);
          }).catch(err => {
              this.$notify({
                group: 'foo',
                type: 'error',
                title: 'Felmeddelande',
                text: err.response.data.message,
                duration: 5000,
                });
          }).finally(() => ( this.fields.certificate.loading = false))
      },

      errors: function(certificate) {
          const domain = this.fields.certificate.domain
          var errors = this.fields.certificate.errors
          if(certificate != null)
          {
              if(!this.checkIfDomainIsCertified) {
                errors.push({
                     name: 'Felaktig SSL', 
                     description: `${domain} finns ej med i listan över domäner som certifierats`, 
                     icon: 'mdi-certificate-outline',
                      color: 'error'
                })  
              }
              
              if(!this.checkIfVerifiedIssuer) {
                  errors.push({
                     name: 'Overified utfärdare', 
                     description: `Utfärdaren finns ej med i vår lista över verifierade utfärdare`, 
                     icon: 'mdi-account-question-outline',
                     color: 'warning'
                }) 
              }
          }
          return errors
      },
  }
}
</script>
