import AuthenticationContext from 'adal-angular/lib/adal.js'
import { app, azure } from './config'

const config = {
  tenant: azure.tenant.name,
  clientId: azure.tenant.id,
  redirectUri: app.url,
  //popUp: true,
  cacheLocation: 'localStorage',
};

export default {
    authenticationContext: null,
    /**
     * @return {Promise}
     */
    initialize() {
      this.authenticationContext = new AuthenticationContext(config);
  
      return new Promise((resolve) => {
        if (this.authenticationContext.isCallback(window.location.hash) || window.self !== window.top) {
          // redirect to the location specified in the url params.
          this.authenticationContext.handleWindowCallback();
        }
        else {
          // try pull the user out of local storage
          let user = this.authenticationContext.getCachedUser();
  
          if (user) {
            resolve();
          }
          else {
            // no user at all - go sign in.
            resolve()
            //this.signIn();
          }
        }
      });
    },
    /**
     * @return {Promise.<String>} A promise that resolves to an ADAL token for resource access
     */
    acquireToken() {
      return new Promise((resolve, reject) => {
        this.authenticationContext.acquireToken(config.clientId, (error, token) => {
          if (error || !token) {
            return reject(error);
          } else {
            return resolve(token);
          }
        });
      });
    },
    /**
     * Issue an interactive authentication request for the current user and the api resource.
     */
    acquireTokenRedirect() {
      this.authenticationContext.acquireTokenRedirect(config.clientId);
    },
    /**
     * @return {Boolean} Indicates if there is a valid, non-expired access token present in localStorage.
     */
    isAuthenticated() {
      // getCachedToken will only return a valid, non-expired token.
      if (this.authenticationContext.getCachedToken(config.clientId)) { return true; }
      return false;
    },
    /**
     * @return An ADAL user profile object.
     */
    getUserProfile() {
      return this.authenticationContext.getCachedUser().profile
    },

    getUserName() {
      return this.authenticationContext.getCachedUser().profile.name
    },

    getUserEmail() {
      const email = (this.authenticationContext.getCachedUser().profile.email != undefined ? this.authenticationContext.getCachedUser().profile.email : this.authenticationContext.getCachedUser().profile.upn)
      return email
    },

    isAdmin() {
      if(!this.isAuthenticated())
        return false
      
      const email = this.getUserEmail()
      const admins = [
          'albin.lindstrom@100procent.com', 'mattias@100procent.com', 'olle.nystrom@100procent.com', 
          'patrick.andersson@100procent.com', 'johan@100procent.com', 'johannes.bjorklund@100procent.com',
          'jonas.hammarberg@100procent.com'
        ]

      return admins.includes(email)
    },

    signIn() {
      this.authenticationContext.login();
    },
    signOut() {
      localStorage.setItem('name', '')
      localStorage.setItem('email', '')
      this.authenticationContext.logOut()
    }
  }
